/**
 * @generated SignedSource<<ca01c1c6bb8f3159813eb55944c734e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type subSectionQuery$data = {
  readonly articles: {
    readonly " $fragmentSpreads": FragmentRefs<"subSectionIndexArticleList">;
  } | null | undefined;
  readonly parentSection?: {
    readonly entityId: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
  };
  readonly relatedTopics: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"entityOnelineMenuQueueItemsEdge">;
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly subSection: {
    readonly advertZone: string | null | undefined;
    readonly entityId: string;
    readonly name: string;
    readonly parentSection: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"sectionDescriptionSection" | "sectionTermLogoSection">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"hooksSubSectionWidgetAndAdsQuery" | "sectionNewsletterWidgetQuery">;
  readonly " $fragmentType": "subSectionQuery";
};
export type subSectionQuery$key = {
  readonly " $data"?: subSectionQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"subSectionQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commentQueueName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "focusQueueName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeComment"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeFocusArticle"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeMultimedia"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includePostMagazine"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "multimediaQueueName"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parentSectionUuid"
},
v9 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "scmpPlusPaywallTypeIds"
},
v10 = {
  "kind": "Variable",
  "name": "entityId",
  "variableName": "entityId"
},
v11 = [
  (v10/*: any*/)
],
v12 = [
  {
    "fields": (v11/*: any*/),
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "version",
      "value": 2
    }
  ],
  "kind": "ScalarField",
  "name": "advertZone",
  "storageKey": "advertZone(version:2)"
},
v16 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "entityUuid",
        "variableName": "parentSectionUuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v17 = {
  "kind": "Variable",
  "name": "commentQueueName",
  "variableName": "commentQueueName"
},
v18 = {
  "kind": "Variable",
  "name": "scmpPlusPaywallTypeIds",
  "variableName": "scmpPlusPaywallTypeIds"
},
v19 = {
  "kind": "Variable",
  "name": "multimediaQueueName",
  "variableName": "multimediaQueueName"
},
v20 = {
  "kind": "Variable",
  "name": "focusQueueName",
  "variableName": "focusQueueName"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "articlesQueueName"
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "defaultValue": 23,
      "kind": "LocalArgument",
      "name": "first"
    },
    (v2/*: any*/),
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hasParentSection"
    },
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "relatedTopicsQueueName"
    },
    (v9/*: any*/)
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "subSectionQuery",
  "selections": [
    {
      "alias": "subSection",
      "args": (v12/*: any*/),
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        (v13/*: any*/),
        (v14/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentSection",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "sectionTermLogoSection"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "sectionDescriptionSection"
        },
        (v15/*: any*/)
      ],
      "storageKey": null
    },
    {
      "condition": "hasParentSection",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "parentSection",
          "args": (v16/*: any*/),
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "section",
          "plural": false,
          "selections": [
            (v13/*: any*/),
            (v14/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "entityLink"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": "relatedTopics",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "relatedTopicsQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "entityOnelineMenuQueueItemsEdge"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "articles",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "articlesQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "after",
              "variableName": "after"
            },
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "first"
            }
          ],
          "kind": "FragmentSpread",
          "name": "subSectionIndexArticleList"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "hooksSubSectionWidgetAndAdsQuery",
      "selections": [
        {
          "alias": "parentSection",
          "args": (v16/*: any*/),
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "section",
          "plural": false,
          "selections": [
            (v14/*: any*/)
          ],
          "storageKey": null
        },
        {
          "condition": "includeComment",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "commentLimit",
                  "value": 6
                },
                (v17/*: any*/),
                (v18/*: any*/),
                {
                  "kind": "Literal",
                  "name": "withHarrysView",
                  "value": false
                }
              ],
              "kind": "FragmentSpread",
              "name": "commentQueueQuery"
            }
          ]
        },
        {
          "condition": "includePostMagazine",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "discoveryWidgetPostMagazineQueueQuery"
            }
          ]
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "hundredTopTablesDiscoveryWidgetQuery"
        },
        {
          "condition": "includeMultimedia",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                (v19/*: any*/)
              ],
              "kind": "FragmentSpread",
              "name": "multimediaQuery"
            }
          ]
        },
        {
          "args": [
            (v18/*: any*/)
          ],
          "kind": "FragmentSpread",
          "name": "openQuestionsSeriesDiscoveryWidgetQuery"
        },
        {
          "alias": "subSection",
          "args": (v12/*: any*/),
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "section",
          "plural": false,
          "selections": [
            (v14/*: any*/),
            (v15/*: any*/)
          ],
          "storageKey": null
        },
        {
          "condition": "includeFocusArticle",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                (v20/*: any*/)
              ],
              "kind": "FragmentSpread",
              "name": "focusArticleHomeFocusArticleQuery"
            }
          ]
        }
      ],
      "args": [
        (v17/*: any*/),
        (v10/*: any*/),
        (v20/*: any*/),
        {
          "kind": "Variable",
          "name": "includeComment",
          "variableName": "includeComment"
        },
        {
          "kind": "Variable",
          "name": "includeFocusArticle",
          "variableName": "includeFocusArticle"
        },
        {
          "kind": "Variable",
          "name": "includeMultimedia",
          "variableName": "includeMultimedia"
        },
        {
          "kind": "Variable",
          "name": "includePostMagazine",
          "variableName": "includePostMagazine"
        },
        (v19/*: any*/),
        {
          "kind": "Variable",
          "name": "parentSectionUuid",
          "variableName": "parentSectionUuid"
        },
        (v18/*: any*/)
      ],
      "argumentDefinitions": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/)
      ]
    },
    {
      "args": (v11/*: any*/),
      "kind": "FragmentSpread",
      "name": "sectionNewsletterWidgetQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "8ce5264e6b72caaff76ae0c5e9b1232f";

export default node;
